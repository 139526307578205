<template>
  <div class="project">
    <Navigation :bgColor='bgColor' :color='color' />
    <main>
      <ul>
        <li v-for="item in list" :key="item.id" @click="news(item.id)">
          <div class="left">
            <p>{{item.createtime_text}}</p>
          </div>
          <div class="right">
            <div>
              <p>{{item.name}}</p>
              <p class="txt-cut">{{item.desc}}</p>
            </div>
          </div>
        </li>
      </ul>
    </main>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>

<script>
import Navigation from "@/components/ipad/Navigation.vue";
import horserace from "@/components/pc/horserace.vue";

export default {
  components: {
    Navigation,
    horserace,
  },
  data() {
    return {
      color: "#64471a",
      list: [],
      noticeColor: "#ffd7d6",
      background: "#64471a",
      bgColor: "#ffd7d6",
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    async getNews() {
      const res = await this.axios("/api/index/news");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.list = data;
        console.log(this.list);
      }
    },
    news(id) {
      console.log(id);
      this.$router.push(`/new?id=${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.project {
  background: #ffd7d6;
  min-height: 100vh;
  padding-bottom: 50px;
  main {
    padding-top: 60px;
    ul {
      li {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        font-size: 20px;
        border-bottom: 1px solid #64471a;
        color: #64471a;
        font-family: Antipol, GLEXME;
        .left {
          width: 50%;
        }
        .right {
          width: 50%;
        }
      }
    }
  }
  .notice {
    height: 50px;
    line-height: 50px;
    font-size: 24px;
  }
}
</style>

